
import React from 'react';
import t from './Locale';

function AgentInfo({ player, visible, goBack }) {

  return (

    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content">
          <div>
            <h2 className="title">{ t('personal_profile') }</h2>

            <p className="agent-info">
              {player.description === '' || player.description === null ? 'El archivo de este agente es TOP SECRET ⚠️' : <span dangerouslySetInnerHTML={{__html: player.description.replaceAll("\n", "<br>") }}></span> }
            </p>

          </div>
        </div>
        <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
  );
}

export default AgentInfo;

