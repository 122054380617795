import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import t from "./Locale";


export default class App extends Component {
  componentDidMount() {
    // === THREE.JS CODE START ===
    var scene = new THREE.Scene();

    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );

    camera.zoom = 1;
    
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
    document.getElementById('scene_container').appendChild( renderer.domElement );
    
    const light = new THREE.AmbientLight( 0x561ebe ); // soft white light
    scene.add( light );
    
    // const light2 = new THREE.PointLight(0xEEEEEE);
    //     light2.position.set(20, 0, 20);
    //     scene.add(light2);

    const controls = new OrbitControls( camera, renderer.domElement );

    camera.position.set( 0, 20, 100 );
    controls.update();

    var objLoader = new OBJLoader();
          // objLoader.setMaterials(materials);
          // objLoader.setPath('/assets/');
          objLoader.load('/assets/Bionuke.obj', function (object) {

              object.traverse(traverser);
              
              scene.add(object);

              // object.position.set( 0, -100, 0 );

          });

    camera.position.set( -100, 150, 100 );
    // camera.lookAt(0, -100, 0);
    controls.update();

    renderer.setClearColor(0x000000, 0);

    var animate = function () {

      requestAnimationFrame( animate );
      // cube.rotation.x += 0.01;
      // cube.rotation.y += 0.01;
      controls.update();
      
      // console.log(camera);

      renderer.render( scene, camera );
    };
    animate();
    // === THREE.JS EXAMPLE CODE END ===
  }
  render() {

    

    return (
      <div className={"page " + (this.props.visible ? "page-in" : "")}>
          <div id="scene_container">

          </div>
          <div>
             <span className="back_btn" onClick={ () => this.props.goBack() }>&lt; { t('back_btn')}</span>
             <span className="gesture"></span>
          </div>
      </div>
    )
  }
}



const traverser = (child) => {
  if (child.type == "Mesh") {
      // console.log("Mesh: " + child.name);
      if (child.mame === "") {
        return;
      }
      else if (child.name === "Cylinder_-_baked") {
        
            // var meshGeomtry = new THREE.WireframeGeometry(child.geometry);
            var toonMaterial = new THREE.MeshToonMaterial({
                color: 0x561ebe,
                emissive: 0x561ebe
            });
            const mesh = new THREE.Mesh( child.geometry, toonMaterial );

            child.add(mesh);
            // child.parent.attach(mesh);
            // child.parent.remove(child);
      }
      else {
          var wireframeGeomtry = new THREE.WireframeGeometry(child.geometry);
          var wireframeMaterial = new THREE.LineBasicMaterial({
              color: 0x318bc0
          });
          var wireframe = new THREE.LineSegments(wireframeGeomtry, wireframeMaterial);
          child.add(wireframe);
          child.parent.attach(wireframe);
          child.parent.remove(child);
      }
  }
  else if (child.type === "Group") {
    for (let i=0; i<child.children.length; ++i) {
      traverser(child.children[i]);
    }
  }
}
