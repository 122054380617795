
import React, { useState, useRef } from 'react';
import Webcam from "react-webcam";
import useWindowDimensions from './useWindowDimensions';

import geiger from './assets/geiger_counter.png';
import t from './Locale';

let globalProcessMode = 0;

function timerCallback(canvas, canvas2, video, width, height, setLevel) {

  // console.log(video.video, video.video.paused, video.video.ended);
  // if (video.video.paused || video.ended) {
  //   return;
  // }
  computeFrame(canvas, canvas2, video.video, width, height, setLevel);
  setTimeout(() => {
    timerCallback(canvas, canvas2, video, width, height, setLevel);
  }, 16); // 60 roughly 60 frames per second
}

function computeFrame(canvas, canvas2, video, width, height, setLevel ) {

  const ctx = canvas.getContext('2d');
  
  // console.log("Painting...", video);
  ctx.drawImage(video, 0, 0, width, height);

  const frame = ctx.getImageData(0, 0, width, height);
  const l = frame.data.length;

  var r = 0;
  var g = 0;
  var b = 0;

  for (var i = 0; i < l; i += 4) {
    r += frame.data[i];
    g += frame.data[i+1];
    b += frame.data[i+2];
  }

  r = Math.floor(r / (frame.data.length / 4));
  g = Math.floor(g / (frame.data.length / 4));
  b = Math.floor(b / (frame.data.length / 4));


  // if (globalProcessMode == 1) {
  //   for (let i = 0; i < l; i++) {
  //     frame.data[i * 4 + 0] = 0xFF - frame.data[i * 4 + 0];
  //     // frame.data[i * 4 + 1] = 0xFF - frame.data[i * 4 + 1];
  //     // frame.data[i * 4 + 2] = 0xFF - frame.data[i * 4 + 2];
  //   }
  // } else if (globalProcessMode == 2) {
  //   for (let i = 0; i < l; i++) {
  //     const grey = (frame.data[i * 4 + 0] + frame.data[i * 4 + 1] + frame.data[i * 4 + 2]) / 3;
  //     frame.data[i * 4 + 0] = grey;
  //     frame.data[i * 4 + 1] = 0xFF - frame.data[i * 4 + 1];
  //     frame.data[i * 4 + 2] = grey;
  //   }
  // }
    // const grey = (frame.data[i * 4 + 0] + frame.data[i * 4 + 1] + frame.data[i * 4 + 2]) / 3;
    // frame.data[i * 4 + 0] = grey;
    // frame.data[i * 4 + 1] = grey;
    // frame.data[i * 4 + 2] = grey;
  
  ctx.putImageData(frame, 0, 0);

  const ctx2 = canvas2.getContext('2d');

  var hsl = rgbToHsl(r, g, b);

  var monitorColor = hslToRgb(hsl.h * 360, 50, 50);

  ctx2.strokeStyle = 'rgb(' + monitorColor.r + ',' + monitorColor.g + ',' + monitorColor.b + ')';
  ctx2.lineWidth = 3;
  //ctx2.fillRect(0, 0, 52, 52);

  ctx2.beginPath();
  ctx2.arc(26, 26, 20, 0, 2 * Math.PI);
  ctx2.stroke();


  

  // setLevel(hsl.h);

  // 1. Calcoliamo la distanza da 0.33 (ovvero il verde...)
  var d = 0.5 - Math.abs(0.33 - hsl.h);

  if (d < 0) {
    d = 0;
  }
  // 
  setLevel(d/50 * 100);

  return;
}

function rgbToHsl(r, g, b) {
  r /= 255; g /= 255; b /= 255;
  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0);break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default:;
    }
    h /= 6;
  }

  return { h: h, s: s, l: l };
}

function hslToRgb(h, s, l) {
  s /= 100;
  l /= 100;
  const k = n => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = n =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return { r: 255 * f(0), g: 255 * f(8), b: 255 * f(4)};
};


function GeigerCounterView(props) {

  const { visible, goBack } = props;
  const canvas = useRef(null);
  const canvas2 = useRef(null);
  const webcamRef = React.useRef(null);
  const [processMode, setProcessMode] = useState(0);

  const [level, setLevel] = useState(0);

  // Sporchissima :-)
  globalProcessMode = processMode;

  const { height, width } = useWindowDimensions();

  const w = 100; // Math.min(height, width) * 0.9;

  const videoConstraints = {
      width: w,
      height: w,
      facingMode: "environment"
  };

  const transform = 'rotate(' + ((level * 90) - 45).toFixed(0) + 'deg)'
  // 

  return (
    
    <div className={"page page-light " + (visible ? "page-in" : "")}>
      <div className="page-content geiger-counter">
          { visible ? <div id="needle" style={{ transform }}></div> : null }
          { visible ?
          <div className="webcampage">
              <div id="webcamview" className="fw">
                <div className="webcamwrapper">
                    <Webcam  videoConstraints={videoConstraints} ref={webcamRef}
                    onUserMedia={(stream) => timerCallback(canvas.current, canvas2.current, webcamRef.current, w, w, setLevel) }
                    />
                    <canvas ref={canvas} style={{ width: w, height: w}} width={w} height={w} />
                    
                  </div> 
                  <canvas id="geiger_counter_color" ref={canvas2} width={52} height={51} />
                
              {/* <ul className="ua-btns">
              <li>
                  <span className="ua-btn" onClick={ () => setProcessMode(0)}>Camera</span>
                </li>
                <li>
                  <span className="ua-btn" onClick={ () => setProcessMode(1)}>Infrared</span>
                </li>
                <li>
                  <span className="ua-btn" onClick={ () => setProcessMode(2)}>Radiactivity</span>
                </li>
              </ul> */}

              {/* <div>{ transform }</div> */}
              </div>
              
          </div>
          : null }
        </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
     
    </div>
  );
}

export default GeigerCounterView;

