

let appLanguage = 'es';

const translations = {
  'it': {
    'personal_profile': 'Profilo Personale',
    'missions': 'Missioni',
    'bionuke_design': 'BioNuke - Vista 3D',
    'qr_reader': 'Apri lettore di QR Code',
    'team': 'Team',
    'status': 'Stato',
    'back_btn': 'Indietro',
    'safety_measures': 'Misure di Sicurezza Tiro a Segno',
    'no_profile': 'La scheda di questo agente è classificata come TOP SECRET ⚠️',
    'geiger_counter': 'Apri Contatore Geiger',
    'wargames_terminal': 'Collegati al Termiale',
    'no_info': 'Nessun informazione disponibile',
    'location': 'Ubicazione',
    'time': 'Ora',
    'mission_not_found': 'Missione non trovata',
    'insert_code': 'Inserire il codice',
    'no_code_required': 'Questa missione non richiede un codice per essere completata',
    'incorrect_code': 'Codice errato',
    'complete_mission': 'Completa la missione',
    'mission_completed': 'Missione completata',
    'no_result': 'Nessun risultato',
    'ok': 'Ok!',
    'code': 'Codice',
    'new_clue': 'Nuovo Indizio',
    'clues': 'Indizi',
    'no_clues': 'Non hai trovato ancora nessun indizio. Li troverai portando a termine le missioni.',
    'app_no_standalone': 'Aggiungi e avvia la app a Home Screen',
    'app_no_standalone_text': 'In questo modo avrai più possibilità di completare le tue missioni e migliorare la tua esperienza di agente. Se non hai idea di come farlo, chiedi aiuto.',
    'open_gps_tracker': 'Apri Localizzatore GPS',
    'tail_view': 'Apri Immagine'
  },
  'es': {
    'personal_profile': 'Perfil Personal',
    'missions': 'Misiones',
    'bionuke_design': 'BioNuke - Vista 3D',
    'qr_reader': 'Lector de código QR',
    'team': 'Equipo',
    'status': 'Estado',
    'back_btn': 'Atrás',
    'safety_measures': 'Medidas de Seguridad Polígono',
    'no_profile': 'El archivo de este agente es TOP SECRET ⚠️',
    'geiger_counter': 'Abrir Contador Geiger',
    'wargames_terminal': 'Conectar en la Terminal',
    'no_info': 'No hay datos disponibles',
    'location': 'Ubicación',
    'time': 'Hora',
    'mission_not_found': 'Misión no encontrada',
    'insert_code': 'Insertar codigo',
    'no_code_required': 'Esta misión no requiere un código para completarse',
    'incorrect_code': 'Código incorrecto',
    'complete_mission': 'Completar la misión',
    'mission_completed': 'Misión cumplida',
    'no_result': 'Ningún resultado',
    'ok': 'Vale!',
    'code': 'Código',
    'new_clue': 'Nueva Pista',
    'clues': 'Pistas',
    'no_clues': 'Aún no has encontrado ninguna pista. Las encontrarás completando misiones.',
    'app_no_standalone': 'Añade la app a la pantalla de inicio',
    'app_no_standalone_text': 'De esta forma tendrás más posibilidades de completar tus misiones y mejorar tu experiencia como agente. Si no tienes idea de cómo hacer esto, pide ayuda.',
    'open_gps_tracker': 'Abrir Localizador GPS',
    'tail_view': 'Abrir Imagen'
  },
  'en': {
    'personal_profile': 'Personal Profile',
    'missions': 'Missions',
    'bionuke_design': 'BioNuke - 3D View',
    'qr_reader': 'QR Code Reader',
    'team': 'Team',
    'status': 'Status',
    'back_btn': 'Back',
    'safety_measures': 'Shooting Safety Measures',
    'no_profile': 'The file of this agent is classified as TOP SECRET ⚠️',
    'geiger_counter': 'Open Geiger Counter',
    'wargames_terminal': 'Connect to Termial',
    'no_info': 'No info available',
    'location': 'Location',
    'time': 'Time',
    'mission_not_found': 'Mission not found',
    'insert_code': 'Insert Code',
    'no_code_required': 'This mission does not require a code to complete',
    'incorrect_code': 'Incorrect Code',
    'complete_mission': 'Complete Mission',
    'mission_completed': 'Mission completed',
    'no_result': 'No result',
    'ok': 'Ok!',
    'code': 'Code',
    'new_clue': 'New Clue',
    'clues': 'Clues',
    'no_clues': 'You haven\'t found any clues yet. You will find them by completing missions.',
    'app_no_standalone': 'Add the app to the Home Screen',
    'app_no_standalone_text': 'This way you will have more chances to complete your missions and improve your agent experience. If you have no idea how to do it, ask for help.',
    'open_gps_tracker': 'Open GPS Locator',
    'tail_view': 'Open Image'
  }
}


const t = (id, lang = null) => {

  if (lang == null) {
    lang = appLanguage;
  }

  if (typeof translations[lang] !== 'undefined' && typeof translations[lang][id] !== 'undefined') {
    return translations[lang][id];
  }

  return id;
}

function setAppLocale(lang) {
  appLanguage = lang;
  console.log("App Locale: ", appLanguage);

}

function getAppLocale() {
  return appLanguage;
}

export default t;
export { setAppLocale, getAppLocale };