
const api_url = "https://alexa.saysource.com/under-attack-editor/api.php"
// const api_url = "http://192.168.2.12/under-attack-editor-dev/api.php"


export function getApiBaseUrl() {
  return api_url;
}

export function getPlayerInfoUrl(id) {
  return getApiBaseUrl() + "?id=" + id + "&cmd=info";
}


export function getSolveMissionUrl(id, mid, code = '') {
  return getApiBaseUrl() + "?id=" + id + "&mid=" + mid + "&cmd=solve&code=" + code;
}
