
import React, { useState } from 'react';
import t from './Locale';

function CluesView(props) {

  const { player, visible, goBack } = props;


  const clues = player.missions.filter( (m) => m.solved && m.clue).map((m, index) => <li key={index} className="text-left clue-item"><div className="text-left" dangerouslySetInnerHTML={{ __html: m.clue }} /></li>);

  return (
    <>
    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content">
          <div className="fw">
            <h2 className="title">{ t('clues') }</h2>
            { clues.length === 0 ? <div>{ t('no_clues')}</div> : 
              <ul className="ua-btns text-right">
                { clues }
              </ul> }
          </div>
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
    </>
  );
}

export default CluesView;

