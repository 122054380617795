
import React from 'react';
import t, { getAppLocale } from './Locale';

function NormasTiro(props) {

  const { visible, goBack } = props;

  return (

    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content" style={{marginTop: 50}}>
          <h2>{ t('safety_measures')}</h2>
            <ol className="ol-list" style={{ textAlign: 'left'}}>
              { getAppLocale() == 'it' ? <>
              <li>Carica e scarica l'arma mirando al bersaglio e all'interno della zona di tiro.</li>
<li>Vietato mirare direttamente o indirettamente le persone.</li>
<li>Non abbandonare l'arma carica, nemmeno per un istante.</li>
<li>Prima di sparare, controlla che l'arma funzioni e che la canna non sia ostruita.</li>
<li>Vietato sparare su un bersaglio diverso dal proprio.</li>
<li>Non mettere il dito sul grilletto finché non è il momento di affrontare il bersaglio.</li>
<li>Non puntare la canna verso l'alto o girare il fucile all'indietro.</li>
<li>Alla fine del tiro al bersaglio, controlla che l'arma sia scarica.</li>
</> : null }

{ getAppLocale() == 'es' ? <>
<li>Cargar y descargar las armas apuntando al blanco y dentro del puesto de tiro.</li>
<li>Prohibido apuntar directa o indirectamente a personas.</li>
<li>No abandonar el arma cargada, ni aún por un instante.</li>
<li>Antes del disparo, comprobar que el arma funciona y el cañón no se halla obstruido.</li>
<li>Prohibido tirar cruzado a un blanco que no sea el propio.</li>
<li>No poner el dedo en la cola del disparador hasta el momento de encarar el blanco.</li>
<li>No apuntar con el cañón hacia arriba o girar hacia atrás con el arma.</li>
<li>Al finalizar la práctica de tiro, comprobar que el arma está descargada.</li>
</> : null }

{ getAppLocale() == 'en' ? <>
<li>Load and unload the weapon while aiming at the target and within the firing zone.</li>
<li>Do not target people directly or indirectly.</li>
<li>Do not let go of your weapon loaded, not even for an instant.</li>
<li>Before shooting, check that the weapon works and that the barrel is not obstructed.</li>
<li>It is forbidden to shoot at a target other than your own.</li>
<li>Don't put your finger on the trigger until it's time to engage your target.</li>
<li>Do not point the barrel up or swing the rifle backwards.</li>
<li>At the end of the target practice, check that the weapon is unloaded.</li>
</> : null }

            </ol>
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
  );
}

export default NormasTiro;

