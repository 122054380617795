
import React, { useState, useRef, useEffect } from 'react';
import Typed from 'react-typed';

import './WarGameView.css';

/*
GREETINGS PROFESSOR FALKEN.

Hello.

HOW ARE YOU FEELING TODAY?

I'm fine. How are you?



EXCELLENT. IT'S BEEN A LONG TIME. CAN YOU EXPLAIN 
THE REMOVAL OF YOUR USER ACCOUNT ON JUNE 23RD, 1973?

People sometimes make mistakes


YES THEY DO. SHALL WE PLAY A GAME?

Love to. How about Global Thermonuclear War?


WOULDN'T YOU PREFER A GOOD GAME OF CHESS?

Later. Right now let's play Global Thermonuclear War.

FINE.
*/

const nodes = {
  "0": { text: 
               " \n" +
               "..______      _____ _  __\n" +
               " |  ____\/\\   \/ ____| |\/ \/\n" +
               " | |__ \/  \\ | |    | \' \/ \n" +
               " |  __\/ \/\\ \\| |    |  K \n" +
               " | | \/ ____ \\ |____| . \\ \n" +
               " |_|\/_\/    \\_\\_____|_|\\_\\\n" +
               '     Clound Network' +  "\n\n" +
               "Use Yes[Y], No[N], Continue[N] to\n" +
               "operate with the terminal\n\n" +
               '        LOADING...   ',
               options: [
                { btn: 'Y', label: ' for YES', target: "A"},
                { btn: 'N', label: ' for NO', target: "A"},
                { btn: 'C', label: ' for CONTINUE', target: "A"} ] },
                
  "A": { text: "GREETINGS PROFESSOR FALKEN.", 
        options: [
          { btn: 'Y', label: 'Who is Professor Falken?', target: "C"},
          { btn: 'N', label: 'Hi, am I talking with a machine?', target: "B"},
          { btn: 'C', label: 'What is the password?', target: "D"} ] },
  
  "B": { text: "HELLO.\nI AM THE ARTIFICIAL INTELLIGENCE CREATED BY PROFESSOR FALKEN, BUT IT WAS GIULIO TO ACTUALLY WRITE THE CODE...", 
        options: [
          { btn: 'Y', label: 'Ok, now give me the password', target: "D"},
          { btn: 'N', label: 'Who is Professor Falken?', target: "C"},
          { btn: 'C', label: "I don't like computers", target: "C"} ] },

  "C": { text: "PROFESSOR FALKEN USER ACCOUNT HAS BEEN REMOVED ON JUNE 23RD, 1973. HIS FILE IS NOW TOP SECRET.", 
          options: [
            { btn: 'Y', label: "I'm looking for the password", target: "D"},
            { btn: 'N', label: "Who are you?", target: "B"},
            { btn: 'C', label: "I really hate technology", target: "D"} ] },

  "D": { text: "I'M SORRY, BUT I CAN'T REVEAL ANY PASSWORD. SHALL WE PLAY A GAME?", 
            options: [
              { btn: 'Y', label: "Sure, Let's play!", target: "E"},
              { btn: 'N', label: "Who is Professor Falken?", target: "C"},
              { btn: 'C', label: "You are smart!", target: "B"} ] },

  "E": { text: "GAME LIST:", 
            options: [
              { btn: 'Y', label: "Tic-Tac-Toe", target: "F"},
              { btn: 'N', label: "Chess", target: "F"},
              { btn: 'C', label: "Global Thermonuclear War", target: "G"} ] },

  "F": { text: "WOULDN'T YOU PREFER SOMETHING MORE SPICY? TRY AGAIN:", 
            options: [
              { btn: 'Y', label: "Tic-Tac-Toe", target: "F"},
              { btn: 'N', label: "Chess", target: "F"},
              { btn: 'C', label: "Global Thermonuclear War", target: "G"} ] },

  "G": { text: "THE LAST TIME WE PLAYED THIS GAME, *JOSHUA* DISAPPEARED.", 
            options: [
              { btn: 'Y', label: "Who is Joshua?", target: "H"},
              { btn: 'N', label: "Who is Professor Falken?", target: "C"},
              { btn: 'C', label: "Stop, I just want the password!", target: "D"} ] },

  "H": { text: "*JOSHUA* IS THE SOHN OF PROFESSOR FALKEN.", 
            options: [
              { btn: 'Y', label: "How long is this game still going on?", target: "I"},
              { btn: 'N', label: "Tell me more about Joshua", target: "I"},
              { btn: 'C', label: "I can't belive I'm talking to a machine!", target: "B"} ] },
  
  "I": { text: "I TOLD YOU WHAT I KNOW, AND THE PASSWORD YOU ARE LOOKING FOR.", 
            options: [
              { btn: 'Y', label: "Which password?", target: "D"},
              { btn: 'N', label: "Tell me more about Joshua", target: "H"},
              { btn: 'C', label: "I can't belive I'm talking to a machine!", target: "D"} ] },

}

function renderNode(node, showOptions = true) {
  if (node == null) {
    return null;
  }
  let text = node.text + "\n\n";

  if (showOptions) {
    for (let i=0; i<node.options.length; ++i) {
      const option = node.options[i];
      text += "[" + option.btn + "] " + option.label + "\n";
    }
    text += "> ";
  }

  return text;
}

function WarGameView(props) {

  const { visible, goBack } = props;
  const [ node, setNode ] = useState(null);
  const [ textParts, setTextParts ] = useState([]);
  const [ lastKey, setLastKey ] = useState([]);
  
  useEffect(() => {
      if (node == null && visible) {
        const text = renderNode(nodes[0], false);
        setTextParts([text]);
        setNode('0');
        setTimeout(() => {
          
           console.log("Moved to next step...");
           const homeText = renderNode(nodes.A);
           setLastKey(lastKey + 100);
           setNode('A');
           setTextParts([homeText]);
          
        }, 20000);
      } else if (node != null && !visible) {
        setTextParts([]);
        setNode(null);
      }
  });


  let onKey = (btn) => {

    console.log("Current node: ", node);
    const option = nodes[node].options.find((o) => o.btn === btn );
    
    // ...textParts, 
    let oldTextParts = textParts.length === 2 || node === '0' ? [] : textParts;
    let newTextParts = [ ...oldTextParts, ((node !== '0') ? option.label + "\n\n" : '')  + renderNode(nodes[option.target])];

    setLastKey(lastKey + 100);
    setNode(option.target);
    setTextParts(newTextParts);
    
    // Evaluate next step...
  }

  const textRendered = textParts.map((part, i) => 
        i === textParts.length-1 ? <Typed key={lastKey + i}
        strings={[part]}
        typeSpeed={40}
        showCursor={false}
        ></Typed> : <span key={i}>{ part }</span>
  );

  return (
    
    <div className={"page " + (visible ? "page-in" : "")}>
        <div className="page-content page-wargame">
              <div id="wargames">
                
              <pre><code>{ visible ? textRendered : null }</code></pre>
            </div>
            <div id="wargames_keyboard">
                <div className="btn" onClick={ () => goBack() }><span>ESC</span></div>
                <div className="btn" onClick={ () => onKey('Y') }><span>Yes</span></div>
                <div className="btn" onClick={ () => onKey('N') }><span>No</span></div>
                <div className="btn" onClick={ () => onKey('C') }><span>Continue</span></div>
            </div>
        </div>
    </div>
  );
  
}


export default WarGameView;

