
import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import nl2br from 'react-nl2br';
import GeigerCounterView from './GeigerCounterView';
import t from './Locale';
import MissionSolveForm from './MissionSolveForm';
import WarGameView from './WarGameView';
import { CSSTransition } from 'react-transition-group';

import fingerprint from './assets/fingerprint.svg';
import MapView from './MapView';
import QRView from './QRView';
import ImageTileView from './ImageTileView';
import ImageGridView from './ImageGridView';

function Mission(props) {

  const { mid, player, visible, goBack } = props;

  const [showTool, setShowTool] = useState(false);
  const [showClue, setShowClue] = useState(false);
  const [showTracker, setShowTracker] = useState(false);
  const [targets, setTargets] = useState([]);
  const [mission, setMission] = useState(null);
  const [solveFormVisible, setSolveFormVisible] = useState(false);

  let content = null;

  const dialogRef = useRef(null);

  const delayedShowClue = () => {
    setTimeout(() => setShowClue(true), 1000);
  }

  useEffect( () => {

    const { mid, player } = props;

    const theMission = player.missions.find((m) => m.id == mid );
    setMission(theMission);
    console.log("Mission targets: ", theMission);
    if (theMission == null || !theMission.m_coords) { 
      setTargets([]);
    } else {
      setTargets(theMission.m_coords ? JSON.parse(theMission.m_coords) : []);
    }
  }, [mid, player]);

  if (mission == null) {
    content = t('mission_not_found');
  }
  else {

      const pagePlaceholderElement = document.getElementById("page-placeholder");

      let tool = null;
      if (mission.tool === 'geiger_counter') {
        tool = 
        <div>
            <div className="ua-btn mb-1 mt-4" onClick={ () => setShowTool(true)}>{ t('geiger_counter') }</div>
              {createPortal(
                <GeigerCounterView visible={showTool} player={player}  goBack={ () => setShowTool(false)} />,
                pagePlaceholderElement
              )}
        </div>
      } else if (mission.tool === 'wargames_terminal') {
        tool = 
        <div>
            <div className="ua-btn mb-1 mt-4" onClick={ () => setShowTool(true)}>{ t('wargames_terminal') }</div>
              {createPortal(
                <WarGameView visible={showTool} player={player}  goBack={ () => setShowTool(false)} />,
                pagePlaceholderElement
              )}
        </div>
      } else if (mission.tool === 'qr_reader') {
        tool = 
        <div>
            <div className="ua-btn mb-1 mt-4" onClick={ () => setShowTool(true)}>{ t('qr_reader') }</div>
              {createPortal(
                <QRView visible={showTool} player={player}  goBack={ () => setShowTool(false)} />,
                pagePlaceholderElement
              )}
        </div>
      } else if (mission.tool === 'image_tile') {
        tool = 
        <div>
            <div className="ua-btn mb-1 mt-4" onClick={ () => setShowTool(true)}>{ t('tail_view') }</div>
              {createPortal(
                <ImageTileView visible={showTool} player={player}  data={mission.tool_data} code={mission.code} goBack={ () => setShowTool(false)} />,
                pagePlaceholderElement
              )}
        </div>
      }

      

      content = (
          <>
            <h2 className="title">{ mission.name }</h2>

            <div className="mission-text">
              {mission.description === null || mission.description === '' ? t('no_info') : <span dangerouslySetInnerHTML={{__html: mission.description.replaceAll("\n", "<br>") }}></span> }
            </div>

            {tool}

            <hr/>

            { mission.m_location !== '' ? 
            <p>
              <label className="ua-label">{ t('location') }</label>
               { mission.m_location }
            </p> : null }

            { targets.length > 0 ? 
            <div>
                  <div className="ua-btn mb-1" onClick={ () => setShowTracker(true)}>{ t('open_gps_tracker') }</div>
                    {createPortal(
                      <MapView visible={showTracker} targets={ targets }  goBack={ () => setShowTracker(false)} />,
                      pagePlaceholderElement
                    )}
            </div> : null }

            { mission.m_time !== '' ? 
            <p>
              <label className="ua-label">{ t('time') }</label>
               { mission.m_time }
            </p> : null }

            

            { mission.solved === 1 ? 
            <div className="fading-green">
              { t('mission_completed') } <br/> { t('code') } { mission.code ? mission.code : null }
            </div> : <MissionSolveForm {...props} onSolve={ mission.clue != null && mission.clue.length > 0 ? delayedShowClue : () => {}}/> }

            <div className="mb-4"></div>
            
            
              {createPortal(
                <CSSTransition nodeRef={dialogRef} in={showClue} timeout={2000} classNames="ua-dialog" unmountOnExit>
                  <div ref={dialogRef}>
                    <div className="ua-dialog-backdrop"></div>
                    <div className="ua-dialog ua-success">
                      <div className="ua-candystripe"></div>
                      <div className="ua-dialog-body">
                        <div className="text-center"><b>{ t('new_clue') }</b></div>
                        <div className="text-center">
                          <img alt="" className="ua-dialog-icon" src={fingerprint}/>
                        </div>
                        <div className="text-center" dangerouslySetInnerHTML={{ __html: mission.clue }}>
                        </div>
                      </div>
                      <div className="ua-dialog-bottom">
                        <span className="ua-btn text-center"  onClick={ () => setShowClue(false) }>{ t('ok') }</span>
                      </div>
                      <div className="ua-candystripe-bottom"></div>
                    </div>
                  </div>
                </CSSTransition>,
                pagePlaceholderElement
              )}
            
            {/* <div className="ua-btn mb-5" onClick={ () => setShowClue(true)}>Show Clue</div> */}
            

          </>
      );
  }

  return (

    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content"  style={{marginTop: 30}}>
          <div className="fw">
            { content }
          </div>
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
  );
}

export default Mission;

