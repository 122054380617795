
import React, { useState } from 'react';
import { getSolveMissionUrl } from './API';
import t from './Locale';



function MissionSolveForm(props) {

  const { mid, player, onCancel } = props;

  let [solveCode, setSolveCode] = useState('');

  let [errorMsg, setErrorMsg] = useState(null);

  const mission = player.missions.find((m) => m.id == mid );


  if (mission == null) {
    return null;
  }

  let help = null
  let errorMessage = null;
  let codeForm = null;

  if (!mission.code) {
    help = (
      <span className="mission-solve-help">
        { t('no_code_required') }
      </span>);
  }
  else {

    codeForm = (
      <div className="mb-3">
        <input placeholder={ t('insert_code')} className={ "ua-solve-code ua-input-text text-center " + (errorMsg ? "invalid-solve-code" : "") } type="text" value={solveCode} onChange={ (e) => setSolveCode(e.target.value)}/>
      </div>);
  }

  if (errorMsg) {
    errorMessage = (
      <span className="">
        { t('incorrect_code') }
      </span>);
  }


  //if (mission.code == '') {
    return (<div>
      {codeForm}
      <span className="ua-btn" onClick={ () => { 

          // console.log(getSolveMissionUrl(player.id, mission.id, solveCode));
          fetch(getSolveMissionUrl(player.id, mission.id, solveCode)).then(
            (response) => response.json().then((data) => {
              if (data.result === 'Success') {
                props.playerChangedCallback();
                props.onSolve();


                if (mission.ws_cmd !== "") {

                  // Send unblock signal to the device...
                  const socket = new WebSocket("wss://wm.saysource.com/ws/");
                  socket.addEventListener('open', (event) => {
                    console.log("Executing WS command..." + mission.ws_cmd);
                    socket.send(mission.ws_cmd);
                  });
                }
              }
              else {
                setErrorMsg(data.message);
                setTimeout(() => setErrorMsg(null), 1000);
              }
            })
          ).catch( (error) => {
            console.log("Error reloading player...", error);
          });

       } }>{ t('complete_mission') }</span>
      {help}
      </div>);
}

export default MissionSolveForm;

