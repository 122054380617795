
import React from 'react';


import t from './Locale';

const averageScreenRatio = 2.2;


class ImageGridView extends React.Component {

  vidRef = React.createRef();

  socket = null;

  state = {
    connectionStatus: '',
    row: 0,
    column: 0,
    transform: '',
    width: 100,
    height: 100
  }
  
  componentDidMount = () => {

    this.openConnection();
    
    // this.socket.addEventListener('message', this.handleMessage.bind(this));
    const wsize = this.getWindowDimensions();

    this.setState({ 
      row: this.props.player.id >= 171 ? 1 : 0,
      column: this.props.player.id >= 171 ? this.props.player.id - 171 : this.props.player.id - 168,
      width: wsize.width,
      height: wsize.height
    }, () => this.calculateTransform());
  }

  componentDidUpdate = (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props.visible !== prevProps.visible && this.props.visible) {
      this.openConnection();
      this.calculateTransform();
    }
  }

  processMessage = (data) => {
    if (data === "START_VIDEO") {
      this.stopVideo();
      setTimeout(() => this.playVideo(), 100);
    }
  }

  handleConnectionClose = () => {
    if (this.props.visible) {
      this.openConnection();
    }
  }

  openConnection = () => {

    if (this.state.connectionStatus == 'open') {
      return;
    }

    this.setState({connectionStatus: 'connecting'} );

    try {
      this.socket = new WebSocket("wss://wm.saysource.com/ws/");
    } catch (error) {
      this.setState({connectionStatus: 'error'} );
    }
    this.socket.addEventListener('error', (e) => this.setState({connectionStatus: 'error'} ));
    this.socket.addEventListener('open', (e) => this.setState({connectionStatus: 'open'} ));
    this.socket.addEventListener('close', (e) => this.handleConnectionClose());
    this.socket.addEventListener('message', (e) => this.processMessage(e.data));

    console.log("Connection reopened");
  }

  calculateTransform = () => {
  
      const screenWidth = this.state.width;
      const row = this.state.row;
      const col = this.state.column;

      const totCols = 6;
      const videoWidth = 1920;
      // const videoHeight = 1080;
      // const screenHeight = screenWidth * averageScreenRatio;
      
      const cw = videoWidth / totCols;
      const rh = cw * averageScreenRatio;
      
      // console.log("screen: " + screenWidth + "x" + screenHeight + " (Grid: " + totCols + "x" + Math.ceil(totCols / 1.77777) + ") [" + row + "," + col + "]");
      // console.log("Portion of video: ", cw);
      
      // Dobbiamo fare in modo che cw = screenWidth
    
      const scaleFactor = screenWidth / cw;
    
      console.log("Video must be scaled to ", scaleFactor, row, rh);
      console.log("VCol ", col, cw);
    
      var t = " scale(" + scaleFactor + ") translate(-" + (col * cw) + "px, -" + (row * rh)  + "px)";
      console.log(t);
      
      this.setState({'transform': t});
  }

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  playVideo = () => {
    this.vidRef.current.play();
  }

  stopVideo = () => {
    this.vidRef.current.pause();
    this.vidRef.current.currentTime = 0;
  }

  render() {

    const { visible, goBack } = this.props;
  
    const letters = 'ABCDEFGHIJKLMNOPQRSTUWXYZ';
  
  // useEffect(() => {
  //     if (visible) {
  //       const t = calculateTransform(width, row, column);
  //       setTransform(t);
  //       if (connectionStatus != 'open') {
  //         openConnection();
  //       }
  //     }
  // });

    return (
      
      <div className={"page page-light " + (visible ? "page-in" : "")}>
        <div className="page-content grid-view">
            <div className="flex-center-content">
              <div style={{ position: 'absolute', top: 0, width: this.state.width, height: Math.min(this.state.height, this.state.width * averageScreenRatio), overflow: 'hidden'}}>
                <div style={{transform: this.state.transform, transformOrigin: 'top left'}}>
                  <video muted playsInline preload="metadata" onClick={() => this.handlePlayVideo() } ref={this.vidRef}>
                      <source src="http://192.168.2.12:8081/newyork.mp4#t=0.001" type="video/mp4"/>
                  </video>
                </div>
              </div>
              <div style={{ position: 'absolute'}}>{ this.state.connectionStatus }<br/>{ letters[this.state.row]} { this.state.column + 1 }</div>
            </div>
        </div>
        <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
      
      </div>
    );
}

}

export default ImageGridView;

