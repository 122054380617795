
import React, { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

import './assets/radar_bg.png';
import t from './Locale';
import './MapView.css';


function MapView(props) {

  const { visible, goBack } = props;

  const { height, width } = useWindowDimensions();
  const [ stage, setStage ] = useState('turned-off');
  const [ flickering, setFlickering ] = useState(false); 


  

  useEffect(() => {
      if (stage === 'turned-off' && visible) {
        setStage('turning-on');
        console.log("turning-on");
        setTimeout(() => { setStage('turned-on'); console.log("turned-on"); }, 2000);
      }

      const runFlickering = () => {
        setFlickering(true);
        setTimeout(() => { setFlickering(false);
          setTimeout(() => {
            runFlickering();
          }, 7000 * Math.random())
        }, 500 * Math.random());
      }

      if (visible) {
        runFlickering();
      }
  },[props.visible]);

  let targets = null;
  if (visible && props.targets) {
    targets = props.targets.map((target, i) => {
      let gradi = Math.atan2( - (target.y - 50), target.x - 50 )*180/ Math.PI;
      if (gradi < 0) { gradi += 360; }
      const mills = (3000/360) * gradi;
      return <div key={i} className="radar-target" style={{ animationDelay: mills + "ms" , left: target.x + '%', top: target.y + '%' }} />
    });
  }


  // w : h = 1080 / 2170
  // 1080 / 2170
  let bg_width = height * 1080 / 2170;
  let rwidth = Math.min(bg_width, width);

  const closeMap = () => {
      setStage('turning-off');
      setTimeout(() => { setStage('turned-off') }, 340);
      setTimeout(() => { goBack(); }, 600);
  }

  return (
    
    <div className={"page " + (visible ? "page-in" : "")}>
      <div className="page-content map-view ">
        <div id="image-preloader"></div>
        <div id="image-preloader2"></div>
        { visible ? <>
        <div className="radar-container" style={{ width: rwidth - 10, height: rwidth - 10 }}>
        <div className={"radar " + stage + " " +  (flickering ? "flickering" : "")} style={{ width: rwidth - 10, height: rwidth }}>
          <div className="pointer"/>
          { targets }
          {/* <div style={{ textAlign: 'center', marginTop: '40px' }}>{ stage }</div> */}
        </div>
        </div>
        <div className="map-view-ui"></div>
        </> : null }
      </div>
      <span className="back_btn" onClick={ () => closeMap() }>&lt; { t('back_btn')}</span>
     
    </div>
  );
}

export default MapView;

