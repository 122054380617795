
import img_hud from './assets/hud.gif';
import './App.css';
import Home from './Home';
import AgentInfo from './AgentInfo';
import Missions from './Missions';
import { useParams } from "react-router-dom";

import { getPlayerInfoUrl } from './API';


import Bretty from './Bretty';

import { useEffect, useState } from 'react';
import QRView from './QRView';
import { setAppLocale } from './Locale';
import ImageGridView from './ImageGridView';
import CluesView from './CluesView';
import MapView from './MapView';
import WarGameView from './WarGameView';

function App() {

  let { pid } = useParams();
  let [activePage, setActivePage] = useState('home');
  let [player, setPlayer ] = useState(null);

  let [loading, setLoading ] = useState(false);
  let [accessDenied, setAccessDenied ] = useState(false);

  let playerChangedCallback = () => {
      if (player) {
          fetch(getPlayerInfoUrl(player.id)).then(
            (response) => response.json().then((data) => {
              setPlayer(data);
            })
          ).catch( (error)=> {
            console.log("Error reloading player (2)...", error);
          });
      }
  }

  useEffect(() => {

    if (player === null && !accessDenied) {

      if (pid) {
        // estraiamo il numero giocatore..
        const arr = pid.split("-"); 

        let realPid = 0;
        if (arr.length === 3) {
          realPid = parseInt(arr[1]) - 7000;
        }
        // b6a067fa-7<id giocatore>-48b1

        setLoading(true);
        setTimeout(() => {
              fetch(getPlayerInfoUrl(realPid)).then(
                (response) => response.json().then((data) => {
                  console.log(data);
                  data.requireUpdate = false;
                  setAppLocale(data.lang);
                  setPlayer(data);
                  setLoading(false);
                })
              ).catch( (error)=> {

                console.log("No pid!!", error);
                setAccessDenied(true);
                setLoading(false);
                setPlayer(null);
              });
            }, 10);
      }
      else {
        console.log("No pid!!");
        setAccessDenied(true);
      }
    }
   }, [player]);

   let content = "";
   
  if (accessDenied) {

    content = (
      <div className="no-access">
        <p>
          Accesso no authorizado
        </p>
      </div>
    );
  }
  else if (loading || player == null) {

    content = (
      <div className="loading">
        <p>
          Loading...
        </p>
      </div>
    )
  } else {
    content = (
      <>
      <Home visible={activePage === 'home'}  player={player} setActivePage={ setActivePage } goBack={ () => setActivePage('home')}/>
      <Bretty visible={activePage === 'bretty'} goBack={ () => setActivePage('home')} />
      <Missions visible={activePage === 'missions'} player={player} goBack={ () => setActivePage('home')} playerChangedCallback={playerChangedCallback} />
      <AgentInfo visible={activePage === 'agent-info'} player={player}  goBack={ () => setActivePage('home')} />
      <CluesView visible={activePage === 'clues-view'} player={player}  goBack={ () => setActivePage('home')} />
      {/* <WarGameView  visible={activePage === 'wargames_terminal'} player={player}  goBack={ () => setActivePage('home')} /> */}
      {/* <QRView visible={activePage === 'qr-reader'} player={player}  goBack={ () => setActivePage('home')} />
      <ImageGridView visible={activePage === 'grid-view'} player={player}  goBack={ () => setActivePage('home')} />
      <MapView visible={activePage === 'map-view'} player={player}  goBack={ () => setActivePage('home')} targets={[{ x: 75, y: 76 }, { x: 75, y: 10 },{ x: 20, y: 76 }]} /> */}
      <div id="page-placeholder"></div>
      </>
    );
  }


  return (
      <div className="App">
        <div className="AppBackground1">
          <img src={img_hud} alt="hud"/>
        </div>
        <div className="AppBackground2"/>
        <div className="AppContainer"/>
          { content }
      </div>
  );
}



export default App;


/*

Cargar y descargar las armas apuntando al blanco y dentro del puesto de tiro.
Prohibido apuntar directa o indirectamente a personas.
No abandonar el arma cargada, ni aún por un instante.
Antes del disparo, comprobar que el arma funciona y el cañón no se halla obstruido.
Prohibido tirar cruzado a un blanco que no sea el propio.
No poner el dedo en la cola del disparador hasta el momento de encarar el blanco.
No apuntar con el cañón hacia arriba o girar hacia atrás con el arma.
Al finalizar la práctica de tiro, comprobar que el arma está descargada.
Se admitirán solamente 3 personas como máximo, en la línea de tiro.
No circular por el polígono con el arma cargada.
Tanto los tiradores como espectadores, deberán guardar en el polígono, la mayor compostura y respeto a las normas que rigen para la disciplina de tiro.

*/