
import React, { useState, useEffect } from 'react';

import t from './Locale';
import './ImageTileView.css';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function ImageTileView(props) {

  const { visible, goBack, data, mission } = props;

  const [cornerIndex, filename] = data.split(',');
  const [clicksCount, setClicksCount] = useState(0);

  const bgImage =  "https://underattack.saysource.com/assets/" + filename ;

  const wsize = getWindowDimensions();
  
  const css = {
    width: wsize.width*2,
    height: wsize.height*2,
  };

  let transform = '';

  if (cornerIndex === '0') { // NW
    transform = 'translate(0,-50%)';
  } else if (cornerIndex === '1') { // NW
    transform = 'translate(0, 0)';
  } else if (cornerIndex === '2') { // NW
    transform = 'translate(-50%,-50%)';
  } else if (cornerIndex === '3') { // NW
    transform = 'translate(-50%,0)';
  }

  let code = props.code.substring(cornerIndex*2, cornerIndex*2 + 2);

  if (clicksCount >= 5) {
    code = <span className="tile-fullcode">Backdoor Found!<br/>{ props.code.substring(0,2) + "-" + props.code.substring(2,4) + "-" +props.code.substring(4,6) + "-" +props.code.substring(6,8) + " "}</span>
  } else {
    code = <span>{code  + "-" + cornerIndex}</span>;
  }

  return (
    <div className={"page " + (visible ? "page-in" : "")}>
      <div className="page-content image-tile-view ">
        { visible ? <>
          <div id="tile-view-wrapper" style={ { ...css, transform: transform }}>
            <img src={bgImage} style={css} alt=""/>
          </div>
          <div id="image-tile-view-code-overlay" className="animated" onClick={ () => setClicksCount(clicksCount + 1)}>{ code }</div>
        </> : null }
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
     
    </div>
  );
}

export default ImageTileView;

