import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import t from "./Locale";


function Home({ player, setActivePage }) {

  let [isStandalone, setIsStandalone ] = useState("not-checked");
  // let [isAndroid, setIsAndroid ] = useState(true);
  let [warningConfirmed, setWarningConfirmed ] = useState(false);
  let [warningReady, setWarningReady ] = useState(false);

  const pagePlaceholderElement = document.getElementById("root");
  const dialogRef = useRef(null);

  useEffect(() => {
    
    let standaloneOrFullscreen = false;
    
    if (typeof window !== 'undefined' && window.nativator !== 'undefined' && window.navigator.standalone !== 'undefined' && window.navigator.standalone) {
      standaloneOrFullscreen = true;
    } else if (matchMedia('(display-mode: standalone)').matches) {
      standaloneOrFullscreen = true;
    } else if (matchMedia('(display-mode: fullscreen)').matches) {
      standaloneOrFullscreen = true;
    } else {
      standaloneOrFullscreen = false;
    }

    // if (!standaloneOrFullscreen) {
    //   var ua = navigator.userAgent.toLowerCase();
    //   setIsAndroid(ua.indexOf("android") > -1);
    // }
    setIsStandalone(standaloneOrFullscreen);
    setTimeout(() => setWarningReady(true), 2000);
    
  });

  return (

    <div className="Home">

        <h2>Hola <b>{ player.name }</b></h2>
        <p>
        { t('team') }: <b>{ player.team }</b><br/>
        { t('status') }: { player.status }<br/>
        </p>

        <ul className="ua-btns">
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('agent-info') } }>{ t('personal_profile') }</span>
            </li>
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('missions') } }>{ t('missions') }</span>
            </li>
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('clues-view') } }>{ t('clues') }</span>
            </li>
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('bretty') } }>{ t('bionuke_design') }</span>
            </li>
            {/* <li>
              <span className="ua-btn" onClick={() => { setActivePage('qr-reader') } }>{ t('qr_reader') }</span>
            </li>
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('grid-view') } }>Grid View</span>
            </li>
            <li>
              <span className="ua-btn" onClick={() => { setActivePage('map-view'); console.log("Showing map") } }>Map View</span>
            </li> */}
            {/* <li>
              <span className="ua-btn" onClick={() => { setActivePage('wargames_terminal') } }>Terminal</span>
            </li> */}
        </ul>

        { createPortal(
            <CSSTransition nodeRef={dialogRef} in={warningReady && isStandalone === false && !warningConfirmed} timeout={2000} classNames="ua-dialog" unmountOnExit>
              <div ref={dialogRef}>
                <div className="ua-dialog-backdrop"></div>
                <div className="ua-dialog ua-alert">
                  <div className="ua-candystripe"></div>
                  <div className="ua-dialog-body">
                  <div className="text-center"><b>SECURITY BREACH</b></div>
                    <div className="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" fill="white" viewBox="-3 3 21 10">
                          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" style={{ filter: "drop-shadow(0px 0px 2px red)" }}/>
                          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"  style={{ filter: "drop-shadow(0px 0px 2px red)" }}/>
                        </svg>
                    </div>

                    <div className="text-center">
                      <b>{ t('app_no_standalone') }</b>
                    </div>
                    <div className="text-center">
                    { t('app_no_standalone_text') }
                    </div>
                  </div>
                  <div className="ua-dialog-bottom">
                    <span className="ua-btn text-center"  onClick={ () => setWarningConfirmed(true) }>{ t('ok') }</span>
                  </div>
                  <div className="ua-candystripe-bottom"></div>
                </div>
              </div>
            </CSSTransition>,
            pagePlaceholderElement) }

    </div>
  );
}

export default Home;

