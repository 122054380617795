
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import t from './Locale';

function QRView(props) {

  const { visible, goBack } = props;
  const [data, setData] = useState(t('no_result'));

  return (

    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content">
          <div className="fw">
            { visible ? <QrReader
                constraints={ {facingMode: 'environment'} }
                className="qreader"
                onResult={(result, error) => {
                  if (!!result) {
                    setData(result?.text);
                  }

                  if (!!error) {
                    console.info(error);
                    // setData("ERROR: " + error);
                  }
                }}
                style={{ width: '100%' }}
              /> : null }
              <p className="mt-4 text-center">{data}</p>
          </div>
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
  );
}

export default QRView;

