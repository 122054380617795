
import React, { useState } from 'react';
import t from './Locale';
import Mission from './Mission';
import NormasTiro from './NormasTiro';

function Missions(props) {

  const { player, visible, goBack } = props;
  let [viewMissionId, setViewMissionId] = useState(null);
  let [viewNormasTiros, setViewNormasTiros] = useState(false);

  let previousSolved = true;
  let missions = player.missions.map( (m, index) => {
      const unlocked = previousSolved;
      previousSolved = m.solved === 1;

      let icon = null;

      if (m.solved) {
          icon = (
            <span className="mission-completed-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
              </svg>
            </span>
          );
      } else if (!unlocked) {
        icon = (
            <span className="mission-locked-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
            </svg></span>
        );
      } else {
        icon = (<span className="mission-unlocked-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-unlock" viewBox="0 0 16 16">
          <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
        </svg></span>)
      }

      return <li key={"m" + m.id } onClick={ unlocked || m.solved ? ()=>setViewMissionId(m.id) : null }>
        
        <span className={"ua-btn " + ((!m.solved && !unlocked) ?  'ua-disabled' : '') }>
          { icon }
          {index + 1} - { m.name }</span>
          </li>;
  });

  return (
    <>
    <div className={"page " + (visible ? "page-in" : "")}>
       <div className="page-content">
          <div className="fw">
            <h2 className="title">{ t('missions') }</h2>
              <ul className="ua-btns">
                { missions }
              </ul>

              <ul className="ua-btns">
                <li>
                  <span className="ua-btn mt-4" onClick={ () => setViewNormasTiros(true)}>{ t('safety_measures') }</span>
                </li>
              </ul>
          </div>
      </div>
      <span className="back_btn" onClick={ () => goBack() }>&lt; { t('back_btn')}</span>
    </div>
    <Mission {...props} mid={viewMissionId} visible={viewMissionId != null} goBack={ () => setViewMissionId(null) }/>
    <NormasTiro visible={viewNormasTiros} goBack={ () => setViewNormasTiros(false) }/>
    </>
  );
}

export default Missions;

